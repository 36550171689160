import logo from './logo.svg'
import './App.css'
import Intro from './components/introSection'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import InvitationCard from './components/introSection'

function App() {
  return (
    <ParallaxProvider>
      <InvitationCard />
    </ParallaxProvider>
  )
}

export default App
