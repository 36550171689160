import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Box,Grid, Divider,Typography, Button, Container, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const BackgroundImg = styled('img')({
   position: 'absolute',
   width: '100%',
   height: '100%',
   objectFit: 'cover',
   zIndex: -1,
   top: 0,
   left: 0,
 });
 
 const CustomPaper = styled(Paper)({
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   padding: '43px 13px',
   backgroundColor: 'rgba(41, 41, 41, 0.7)',
   backdropFilter: 'blur(5.349999904632568px)',
   position: 'absolute', // Changed to absolute to fill the screen
   top: 0,
   left: 0,
   right: 0,
   bottom: 0,
   zIndex: 2, // Ensure it's above the background image
 });
const CustomImg = styled('img')({
  width: '100%',
  height: 'auto',
});



const CustomIconButton = styled(IconButton)({
  marginTop: '67px',
});
const openGoogleMaps = () => {
   // Example location: Eiffel Tower
   const location = "SET+LOUNGE,Azurara"; // Replace this with your specific location
   window.open(`https://www.google.com/maps/search/?api=1&query=${location}`, '_blank');
 };
function InvitationCard() {
   const [open, setOpen] = React.useState(false); // Para controlar a visibilidade do Dialog
  const [name, setName] = React.useState(''); // Para armazenar o nome do convidado
  const [guests, setGuests] = React.useState(''); // Para armazenar o número de convidados
  const [feedback, setFeedback] = React.useState(''); // Para armazenar o feedback após envio

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRSVP = async () => {
   const webhookUrl = "https://discord.com/api/webhooks/1211356228232548442/d8gRC3jkJ0s4atTVRapSkWvnaQIEpDEAdhAt7up511pkr2YJ4DqoVl0-zALLk9ShFB7n";
  const messageData = {
    content: `Nome: ${name}, Convidados: ${guests}`
  };

  try {
    const response = await fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageData),
    });

    if (response.ok) {
      toast("Sua presença foi confirmada com sucesso!");
      
      setOpen(false); // Fechar o dialog após envio
    } else {
   
      throw new Error('Falha ao enviar mensagem');
    }
  } catch (error) {
    console.error('Erro ao enviar mensagem para o webhook do Discord:', error);
    toast("Houve um erro ao confirmar sua presença. Por favor, tente novamente.");
   
  }
  };
  return (
    <Box sx={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden',color:"white" }}>
       <ToastContainer />
      <BackgroundImg
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ddca4a4a5dc14c25a9c592b9d0c84d388fe85a71429c47362a628732d7fd381?apiKey=a64ddb93f80e4da0848ead67de2cf723&"
        alt="Background"
      />
      <Container width="100vw">
        <CustomPaper elevation={3}>
          <CustomImg
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/70d04ce80a3d2a595b665a3c438559c671e078782f224025c6a38cd4034af126?apiKey=a64ddb93f80e4da0848ead67de2cf723&"
            alt="Couple"
            sx={{ width: 85, aspectRatio: '0.9' }}
          />
          <Typography variant="body2" sx={{ marginTop: '34px', textAlign: 'center', fontSize: 14 ,color:"white"}}>
            Amar não é olhar um para o outro, é olhar juntos na mesma direção.
          </Typography>
          <Typography variant="h5" sx={{ marginTop: '43px', textAlign: 'center' ,color:"white"}}>
            Convite Casamento 
          </Typography>
          <Typography variant="h3" sx={{  textAlign: 'center' ,color:"white"}}>
            Bruna & Tiago
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: '6px' ,color:"white"}}>
          <Grid container direction="column">
              <Typography variant="h6">
              Sábado
            </Typography>
            <Typography variant="caption">
              14:30h
            </Typography>
            </Grid>
            <Divider orientation="vertical"  sx={{backgroundColor:"white"}}flexItem />
            <Typography variant="h4" sx={{ fontWeight: 700,color:"white" }}>
              14
            </Typography>
            <Divider orientation="vertical" sx={{backgroundColor:"white"}}flexItem />
            <Grid container direction="column">
              <Typography variant="h6">
              Setembro
            </Typography>
            <Typography variant="caption">
              2024
            </Typography>
            </Grid>
          </Box>
          <CustomIconButton onClick={openGoogleMaps}>
            <LocationOnIcon sx={{ width: 37, height: 37 ,color:"white"}} />
          </CustomIconButton>
          <Typography variant="body2" marginTop={2} sx={{color:"white"}}>
            Localização
          </Typography>
          <Button variant="contained" onClick={handleClickOpen} sx={{ marginTop: '112px', backgroundColor: 'rgba(90, 104, 79, 0.5)', '&:hover': { backgroundColor: 'rgba(90, 104, 79, 0.7)' } }}>
            Confirmar Presença
          </Button>
          <Typography variant="caption" display="block" marginBottom={5} sx={{color:"gray"}}>
            até 1 de agosto
          </Typography>
        </CustomPaper>
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmar Presença</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, insira seu nome e o número de convidados.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="guests"
            label="Número de Convidados"
            type="number"
            fullWidth
            variant="standard"
            value={guests}
            onChange={(e) => setGuests(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRSVP}>Enviar</Button>
        </DialogActions>
      </Dialog>
     
      </Container>
    </Box>
  );
}

export default InvitationCard;