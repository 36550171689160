import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider, createTheme } from '@mui/material'
import '@fontsource/nothing-you-could-do'
import '@fontsource-variable/raleway'
import '@fontsource-variable/dancing-script'
const theme = createTheme({
  palette: {
    primary: {
      main: '#778d6f',
    },
    secondary: {
      main: '#778d6f',
    },
  },
  typography: {
    h3: {
      color: '#f3f3f3',
      fontFamily: 'Dancing Script Variable',
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
